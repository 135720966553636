<template>
  <div class="drop-lead-modal-content">
    <h2 class="mb-3">Drop Lead</h2>

    <fd-form @submit="drop">
      <fd-select
        v-model="presetReason"
        class="col-12 mb-2"
        label="Select a Reason"
        :options="presetReasons"
        :optionValue="(val) => val"
        :optionLabel="(val) => val"
        :validators="[validator.required]"
      >
      </fd-select>

      <fd-textarea
        v-if="presetReason == 'Other'"
        v-model="form.reason"
        class="col-12 mb-2"
        label="Other Reason"
        name="dropLeadOtherReason"
        :validators="[validator.required]"
      >
      </fd-textarea>

      <p v-if="presetReason" class="bordered-card p-2 mb-3">
        <span
          v-html="presetReason === 'Other' ? this.form.reason : presetReason"
          :style="{ whiteSpace: 'pre-wrap' }"
        ></span>
      </p>

      <div class="d-flex justify-content-end">
        <fd-button class="mr-2" @click="$emit('cancel')"> Cancel </fd-button>
        <fd-button type="submit" class="danger main"> Drop </fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      presetReason: "",
      presetReasons: [
        "Owner is uncontactable.",
        "Owner no longer wants to sell/rent.",
        "Other"
      ],

      form: {
        reason: ""
      },

      validator: {
        required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    drop() {
      if (this.presetReason !== "Other") {
        this.form.reason = this.presetReason;
      }
      this.$emit("drop", this.form);
    }
  }
};
</script>

<style lang="scss"></style>
